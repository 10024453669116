import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";
import { useFormStatus } from "react-dom";
const ButtonOutline = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<"button">>(function ButtonBase({
  className = "",
  ...props
}, ref) {
  const {
    pending
  } = useFormStatus();
  return <button {...props} ref={ref} disabled={props.disabled ?? pending} className={`${className} inline-flex items-center justify-center rounded-md border border-none bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-900 disabled:bg-gray-400`} />;
});
export default ButtonOutline;